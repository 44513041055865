export enum AlertType {
    error,
    success,
    confirm,
    info
}

export enum LeadStatus {
    Pending = 'Pending',
    Accepted = 'Accepted',
    Rejected = 'Rejected'
}

export enum InstallerStatus {
    Pending = 'pending',
    Accepted = 'accepted',
    Rejected = 'rejected',
    Recommended = 'recommended'
}
