import { Address, UTMParams } from './user.model';
import { Installer, BreakUp } from './installer.model';
import { InstallerQuote } from './quote.model';


export class ContractDocument {
    added_date: string;
    file_name: string;
    file_size: number;
    original_file_name: string;
}
export class Lead {
    lead_id: string;
    email: string;
    phone: string;
    zip: string;
    // enlighten_user_id
    save_estimate_only_lead: boolean;
    ci_quote_only: boolean;
    have_solar_installer: boolean;
    customer_type: string;
    product_type: string;
    program_name: string;
    installer_company_name: string;
    full_name: string;
    first_name: string;
    last_name: string;
    stage: string;
    est_input_option: string;
    ec_bill_amt: number;
    installers_config: {
        show_install_only_installers: boolean;
        show_ci_as_install_only: boolean;
        show_turnkey_installers: boolean;
        show_ci_as_turnkey: boolean;
        show_number_of_turnkey_installers: number;
        show_number_of_install_only_installers: number;
    };
    heard_about_enphase: string;
    new_system: NewSystem;
    existing_system: ExistingSystem | null;
    address: Address;
    full_address: string;
    system_summary_s3_link: String;
    created_time: number;
    last_upddated_time: number;
    installers: Array<Installer>;
    min_net_price: number;
    max_net_price: number;
    main_panel_bus_rating: number;
    main_breaker_rating: number;
    s3_utility_bill_file_name: string;
    s3_mfg_label_file_name: string;
    s3_breaker_pic_file_name: string;
    s3_site_pic1_file_name: string;
    s3_site_pic2_file_name: string;
    individual_pricing_enabled: boolean;
    aggregated_price_breakup: BreakUp;
    additional_info: string;
    existing_panels_count: number;
    existing_pv_size: number;
    additional_savings_pa: number;
    min_estimated_investment: number;
    gamification_source: string;
    gamification_src_identifier: string;
    own_house: boolean;
    installation_timeline: string;
    unsure_of_system_info: boolean;
    existing_installation_year: number;
    existing_pv_installation_year: number;
    max_estimated_investment: number;
    min_roi: number;
    max_roi: number;
    gamification_src_comments: string;
    power_generated_pa: number;
    locale: string;
    system_summary_link: string;
    created_by_uname: string;
    created_by_utype: string;
    lead_referred_by_installer: number;
    selected_utility_provider: string;
    selected_utility_rate_structure: string;
    selected_finance_option: string;
    selected_consumption_timings: Array<number>;
    storage_mode: string;
    loan_downpayment: number;
    annual_increase_bill_rate: number;
    loan_amount: number;
    loan_emi: number;
    new_electricity_bill: number;
    total_cost: number;
    incentive_amount: number;
    fed_tax_inc_rate: number;
    sgip_inc_rate: number;
    itc_amount: number;
    sgip_amount: number;
    system_sized_by: String;
    lead_source: String;
    source_details: String;


    total_savings: number;
    loan_interest_rate: number;
    loan_term: number;
    solar_price: number;
    storage_price: number;
    size_preference_unit: string;
    energy_independence: number;
    se_version: string;
    tariff_rate: number;
    solar_req: Boolean;
    storage_req: Boolean;
    micro_req: Boolean;
    solar_add_req: Boolean;
    requirement: string;
    time_zone: string;
    version?: string;
    journey?: any;
    savings_mode_settings: PriorityModeSettings;
    ei_mode_settings: PriorityModeSettings;
    backup_mode_settings: PriorityModeSettings;
    consumption_preference: string;
    page_url: string;
    utm_params: UTMParams;
    system_summary_data: string;
    require_qualified_leads: boolean;
    iq8_matched_installer: number;
    slf_shading_info: string;
    slf_property_type: string;
    electric_utility:string;
    ownership_status:string;
    enphase_branded:string;
    sandbox:string;

    // for SLF form integration
    universal_leadid: string;
    landing_page: string;
    lp_type: string;
    xxTrustedFormCertUrl: string;

    constructor(lead?: any) {
        Object.assign(this, lead);
        // lead && (lead.new_system is not instence of NewSystem) && (lead.new_system is not null)
        if (lead && lead.hasOwnProperty('new_system') && lead.new_system !== null) {
            this.new_system = new NewSystem(lead.new_system);
        }
        if (lead && lead.hasOwnProperty('existing_system') && lead.existing_system !== null) {
            this.existing_system = new ExistingSystem(lead.existing_system);
        }
        if (lead && lead.hasOwnProperty('installers') && lead.installers !== null) {
            this.installers = lead.installers.map(installer => new Installer(installer));
        }
    }
}

export class PriorityModeSettings {
    selected_appliances: string[];
    appliances_selected_for_outage: string[];
    simultaneous_appliances: string[];
    hours_of_backup: number;
    pv_size: number;
    storage_size: number;
}

export class Polygon {
    vertices: Array<Vertex>;

    constructor(newpolygon?: any) {
        Object.assign(this, newpolygon);
    }
}

export class Vertex {

    lat: number;
    lng: number;

    constructor(newVertex?: any) {
        Object.assign(this, newVertex);
    }
}

export class NewSystem {
    home_size = 0;
    preferred_time_to_call = '';
    daily_consumption = 0;
    selected_appliances: string[] = [];
    appliances_selected_for_outage: string[] = [];
    hours_of_backup = 0;
    cloudy_days: backupType;
    sunny_days: backupType;
    roof_area_covered = 0;
    solar_requirement: SolarRequirement | null;
    storage_requirement?: StorageRequirement | null;
    appliances_config: Appliance[];
    simultaneous_appliances: string[];
    constructor(newSystem?: any) {
        Object.assign(this, newSystem);
    }
}

export class Appliance {
    appliance_id: string;
    category: string;
    code: string;
    name: string;
    operating_power_factor: string;
    surge_power_factor: string;
    daily_usage_hours: number;
    operating_power: number;
    surge_power: number;
    quantity: number;
    enable_for_outage: boolean;
    soft_starter: boolean | null;
    constructor(appliance?: any) {
        Object.assign(this, appliance);
    }
}

export class ExistingSystem {
    site_id: number;
    storage: StorageRequirement | null;
    solar: SolarRequirement | null;
    constructor(existingSystem?: any) {
        Object.assign(this, existingSystem);
    }
}

export class StorageRequirement {
    storage_capacity?: number;
    enc10_count?: number;
    enc3_count?: number;
    enc5_count?: number;
    suggested_capacity?: number;
    backup_preference?: String;
    site_encharges?: encharge[];
    constructor(storageRequirement?: any) {
        Object.assign(this, storageRequirement);
    }
}

export class SolarRequirement {
    panel_count = 0;
    pv_size = 0;
    pv_modules: PvModules[] | null;
    micro_type?: string;
    constructor(solarRequirement?: any) {
        Object.assign(this, solarRequirement);
    }
}

export class PvModules {
    type: string;
    name: string;
    count: number;
    constructor(pvModules?: any) {
        Object.assign(this, pvModules);
    }
}

export class backupType {
    days = 0;
    hours = 0;
    minutes = 0;
    constructor(backup?: any) {
        Object.assign(this, backup);
    }
}

export class encharge {
    id: number;
    type: string;
    part_num: string;
    parent_id: number;
    serial_num: string;
}

export class LeadJourney {
    installer_lead_journey_id: string;
    installer_id: number;
    lead_id: string;
    current_state: LeadState;
    states: LeadState[];
    quotes: Array<InstallerQuote>;
    contract_journey: LeadState[];
    order_journey: LeadState[];
    journey_type: string;
    order_id: string;
    payment_id: string;
    order_placed_by: string;
    total_messages_count: number;
    unread_installer_messages_count: number;
    unread_homeowner_messages_count: number;
    share_contact_document_to_ho: boolean;
    contract_documents: ContractDocument[];
    sg_project_id: string;
    sg_project_public_id: string;
    constructor(leadJourney?: any) {
        Object.assign(this, leadJourney);
        if (leadJourney.current_state) {
            this.current_state = new LeadState(leadJourney.current_state);
        }
        if (leadJourney.states) {
            this.states = leadJourney.states.map((state) => {
                return new LeadState(state);
            });
        }
    }
}
export class LeadState {
    state: string;
    completion_date: string;
    time_stamp: string;
    created_time: number;
    last_upddated_time: number;
    state_id: number;
    constructor(state?: any) {
        Object.assign(this, state);
    }
}