import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpEvent, HttpHandler, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable()
export class HTTPInterceptorsService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const request = req.clone({
      setHeaders: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    });
    return next.handle(request);
  }
}

export let httpInterceptors = {
  provide: HTTP_INTERCEPTORS,
  useClass: HTTPInterceptorsService,
  multi: true
};
