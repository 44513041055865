export enum Product_Type {
    storage = 'storage',
    solar = 'solar',
    solarstorage = 'solarstorage',
    upgrade = 'upgrade'
}

export enum LeadJourneyStatus {
    EPRecommended = 'EPRecommended',
    EPEstimationDone = 'EPEstimationDone',
    EPLeadExpired = 'EPLeadExpired',
    EPDetailsSentToInstaller = 'EPDetailsSentToInstaller',
    EPAcceptedByInstaller = 'EPAcceptedByInstaller',
    EPRejectedByInstaller = 'EPRejectedByInstaller',
    EPQuoteGenerated = 'EPQuoteGenerated',
    EPQuoteSeen = 'EPQuoteSeen',
    EPInstallerContacted = 'EPInstallerContacted',
    EPSiteAssessed = 'EPSiteAssesed',
    EPContractSigned = 'EPContractSigned',
    EPPermitCompleted = 'EPPermitCompleted',
    EPInstallationDone = 'EPInstallationDone',
    EPSystemReady = 'EPSystemReady',
    EPRejectedByHomeOwner = 'EPRejectedByHomeOwner',
}

export enum OrderJourneyStatus {
    EPOrderProcessing = 'EPOrderProcessing',
    EPOrderConfirmed = 'EPOrderConfirmed',
    EPOrderShipped = 'EPOrderShipped',
    EPOrderDelivered = 'EPOrderDelivered',
    EPOrderCancelled = 'EPOrderCancelled'
}

export enum ContractJourneyStatus {
    EPContractShared = 'EPContractShared',
    EPContractRejected = 'EPContractRejected',
}
