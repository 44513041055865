import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Lead } from '@se/core/models';
import { ApiService } from '../_helpers/api.service';
import { REST_BASE_URL, REST_URLS } from '@se/core/constants';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LeadsService {
  constructor(private _apiService: ApiService) { }
  public isV3 = false;
  insertLead(lead: Lead): Observable<Lead> {
    // poupluate SLF fields for lead
    const xxTrustedFormCertUrl = document.getElementById('xxTrustedFormCertUrl_0') as HTMLInputElement;
    const uleadid = document.getElementById('leadid_token') as HTMLInputElement;
    lead.universal_leadid = (uleadid)?uleadid.value:'';
    lead.landing_page = "form-estimator";
    lead.lp_type =  "ussp-multi-form";
    lead.ownership_status = 'on';
    lead.enphase_branded = '1';
    lead.sandbox = '1';
    lead.xxTrustedFormCertUrl = (xxTrustedFormCertUrl)?xxTrustedFormCertUrl.value:'';
    return this._apiService.post(REST_BASE_URL.B2C, this._apiService.composeUrl([REST_URLS.POST.LEADS]), lead);
  }
  updateExistingLead(lead: Lead, lead_id): Observable<Lead> {
    return this._apiService.put(REST_BASE_URL.B2C, this._apiService.composeUrl([REST_URLS.PUT.LEADS, lead_id]), lead);
  }
  updateLead(leadId, newLead, main_panel_bus_rating, main_breaker_rating): Observable<Lead> {
    return this._apiService.put(REST_BASE_URL.B2C, this._apiService.composeUrl([REST_URLS.POST.LEADS, leadId, 'new_system']), newLead, this._apiService.composeUrlParams(['main_panel_bus_rating', 'main_breaker_rating'], [main_panel_bus_rating, main_breaker_rating]));
  }
  updateLeadInfo(leadId, data): Observable<Lead> {
    return this._apiService.put(REST_BASE_URL.B2C, this._apiService.composeUrl([REST_URLS.POST.LEADS, leadId, 'info']), data);
  }
  get(siteId: number): Observable<Lead> {
    return this._apiService.get(REST_BASE_URL.B2C, this._apiService.composeUrl([REST_URLS.GET.SEARCH_LEADS]), this._apiService.composeUrlParams(['site_id'], [siteId])).pipe(
      map(result => {
        this.isV3 = result.se_version === 'v3';
        return new Lead(result);
      })
    );
  }
  getUploadImageLink(fileName, httpMethod): Observable<any> {
    return this._apiService.get(REST_BASE_URL.B2C, this._apiService.composeUrl([REST_URLS.GET.QUOTE_UPLOAD_IMAGES]), this._apiService.composeUrlParams(['file_name', 'http_method'], [fileName, httpMethod]))
  }
}
