import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services';
import { APP_ROUTE } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class LoggedInAuthGuardGuard implements CanActivate {
  constructor(private _authService: AuthenticationService, private _router: Router) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this._authService.isUserLoggedIn) {
      this._router.navigate([APP_ROUTE.LEAD.common]);
      return false;
    } else {
      return true;
    }
  }
}
