import { NgModule, Optional, SkipSelf } from '@angular/core';
import { ToasterService } from './utility/toast.service';
import { MatSnackBarModule } from '@angular/material';
import { SelectivePreloadingStrategyService } from './_helpers/selective-preloading-strategy.service';
import { AuthGuard } from './guards';
import { httpInterceptors, errorInterceptors, authInterceptors } from './interceptors';
import { SystemService, CommunicationService } from './utility';

// const interceptors = [httpInterceptors, errorInterceptors, authInterceptors];
// const utility = [SystemService, CommunicationService, ToasterService, SelectivePreloadingStrategyService , ChunkErrorHandler];
// const services = [];
// const guards = [AuthGuard];
@NgModule({
  imports: [],
  exports: [MatSnackBarModule],
  declarations: []
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
  static forRoot() {
    return {
      ngModule: CoreModule,
      // providers: [...interceptors, ...utility, ...guards, ...services]
      providers: [
        httpInterceptors, errorInterceptors, authInterceptors,
        SystemService, CommunicationService, ToasterService, SelectivePreloadingStrategyService,
        AuthGuard
      ]
    };
  }
}
