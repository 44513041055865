import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpEvent, HttpHandler, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../services';

@Injectable()
export class ErrorInterceptorsService implements HttpInterceptor {
  constructor(private _auth: AuthenticationService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (this._auth.isUserLoggedIn && !(err.url.includes('systems/profile') || err.url.includes('systems/system_meta_data') || err.url.includes('v1/appliances')) && err.status === 401) {
        // auto logout if 401 response returned from api
        this._auth.logout();
        // location.reload(true);
      }
      const error = err.error; // .message || err.statusText;
      return throwError(error);
    }));
  }
}

export let errorInterceptors = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorInterceptorsService,
  multi: true
};
