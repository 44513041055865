import { Injectable } from '@angular/core';
import { Login, LoginResponse, User } from '@se/core/models';
import { Observable, BehaviorSubject } from 'rxjs';
import { REST_BASE_URL, REST_URLS, WEB_STORAGE_KEYS, APP_ROUTE } from '@se/core/constants';
import { ApiService } from '../_helpers/api.service';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { WebStorageService } from '../utility';
import { LeadsService } from './leads.service';

declare global {
  interface Window { dataLayer: any[]; fby: any; ga: any }
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public openedFromHO = false;
  public showWelcomeScreen = true;
  private currentUserSubject: BehaviorSubject<LoginResponse>;
  public ISRUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<LoginResponse>;
  public ISRUser: Observable<any>;
  public loadNewLoginDialog: BehaviorSubject<any>;
  public webPSupported = false;

  // store the URL so we can redirect after logging in
  public redirectUrl: string;

  constructor(private _apiService: ApiService, private _router: Router, private _webStorage: WebStorageService, private _lead: LeadsService) {
    this.currentUserSubject = new BehaviorSubject<LoginResponse>(JSON.parse(_webStorage.get(WEB_STORAGE_KEYS.auth)));
    this.currentUser = this.currentUserSubject.asObservable();
    this.ISRUserSubject = new BehaviorSubject<any>(_webStorage.get(WEB_STORAGE_KEYS.enlighten_admin) || null);
    this.ISRUser = this.ISRUserSubject.asObservable();
    this.loadNewLoginDialog = new BehaviorSubject(false);

    const elem = document.createElement('canvas');
    if (!!(elem.getContext && elem.getContext('2d'))) {
      this.webPSupported = elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }
  }

  public get currentUserValue(): LoginResponse {
    return this.currentUserSubject.value;
  }
  public get isUserLoggedIn(): boolean {
    const currentUser = this.currentUserValue;
    if (currentUser && currentUser.token) {
      return true;
    }
    return false;
  }
  setToken(token, authdata) {
    this._webStorage.set(WEB_STORAGE_KEYS.auth, JSON.stringify({ token, authdata }));
    this.currentUserSubject.next(new LoginResponse({ token, authdata }));
  }
  login(login: Login): Observable<LoginResponse> {
    return this._apiService.post(REST_BASE_URL.B2C, this._apiService.composeUrl([REST_URLS.POST.LOGIN]), login)
      .pipe(map(user => {
        // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
        user.authdata = (login.username);
        this._webStorage.set(WEB_STORAGE_KEYS.auth, JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
  }
  logout(enligthenAdmin = false) {
    // remove user from local storage to log user out
    this._webStorage.destroy(WEB_STORAGE_KEYS.auth);
    this.currentUserSubject.next(null);
    if (!enligthenAdmin) {  // If user is enligthen manager do not navigate to login page
      let URL = APP_ROUTE.LOGIN;
      if (window.location.href.includes('pse-us')) {
        URL = 'pse-us';
      } else if (this._lead.isV3) {
        URL = APP_ROUTE.V3;
      }

      this._router.navigate([URL]).then(() => {
        if (URL !== APP_ROUTE.LOGIN) {
          location.reload();
        }
      });
    } else {
      this.ISRUserSubject.next('');
      this._webStorage.destroy(WEB_STORAGE_KEYS.enlighten_admin);
    }
  }
}
