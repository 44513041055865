import { Injectable } from '@angular/core';
import { ApiService } from '../_helpers/api.service';
import { User } from '@se/core/models';
import { Observable } from 'rxjs';
import { REST_URLS, REST_BASE_URL } from '@se/core/constants';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private _api: ApiService) { }

  register(user: User): Observable<User> {
    return this._api.post(REST_BASE_URL.B2C, this._api.composeUrl([REST_URLS.POST.USERS]), user);
  }

  updateUser(user: User): Observable<User> {
    return this._api.put(REST_BASE_URL.B2C, this._api.composeUrl([REST_URLS.PUT.USERS]), user);
  }

  setOtpPassword(data): Observable<any> {
    return this._api.post(REST_BASE_URL.B2C, this._api.composeUrl([REST_URLS.POST.SET_OTP_PASSWORD]), data);
  }

  getUserInfo(): Observable<any> {
    return this._api.get(REST_BASE_URL.B2C, this._api.composeUrl([REST_URLS.POST.USER_INFO]));
  }

  resendOtp(lead_id): Observable<any> {
    return this._api.post(REST_BASE_URL.B2C, this._api.composeUrl([REST_URLS.POST.RESEND_OTP]), { lead_id });
  }
}
