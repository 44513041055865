export class InstallerQuote {
    quote_id: string;
    lead_id: string;
    installer_id: number;
    created_time: number;
    updated_time: number;
    pv_size: number;
    storage_capacity: number;
    panel_type: string;
    panel_mfg: string;
    panel_model: string;
    panel_qty: number;
    tilt: number;
    inverter_type: string;
    inverter_qty: number;
    encharge10_qty: number;
    encharge3_qty: number;
    quote_url: string;
    s3_quote_file_name: string;
    total_price: number;
    system_price: number;
    installation_price: number;
    incentives: number;
    final_price: number;
    savings: number;
    payback_period: number;
    installer_note: string;
    cash_quote: boolean;
    loan_quote: boolean;
    lq_total_price: number;
    lq_incentives: number;
    lq_final_price: number;
    lq_savings: number;
    lq_payback_period: number;
    lq_loan_amount: number;
    lq_interest_rate: number;
    lq_loan_term_in_months: number;
    lq_downpayment: number;
    lq_monthly_payment: number;
    lq_selected_finance_provider: string;
    lq_custom_finance_provider: string;
    constructor(quote?: any) {
        Object.assign(this, quote);
    }
}
