
export enum REST_BASE_URL {
    B2C = 'B2C',
    B2C_NODE = 'B2C_NODE',
    SOLAR_ROOF = 'SOLAR_ROOF',
    SAT = 'SAT',
    SG = 'SOLARGRAFAPI',
    GERMNAYSE = 'GERMNAYSE',
    APILAYER = 'APILAYER',
    BOMCALCULATOR = 'BOMCALCULATOR',
    ENPHASEWEBSITE = 'ENPHASEWEBSITE'
}
