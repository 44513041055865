export class Sites {
    active_cell_modems_present = false;
    active_combiners_present = false;
    active_cts_present = false;
    active_envoys_present = true;
    address1 = '';
    address2 = '';
    city = '';
    country = '';
    email = '';
    first_name = '';
    inverters = { IQ6: 0, IQ7: 0 };
    last_name = '';
    pv_size = 0;
    site_id = 0;
    pv_modules = [];
    envoys = [];
    encharges = [];
    site_name = '';
    state = '';
    status = '';
    zip = '';
    IQCount = 0;
    iqs = [];
    non_compatible_invs_count = 0;
    m250_210_count = 0;
    total_panels = 0;
    encharge_count = 0;
    envoySCount = 0;
    pv_on_older_micros = 0;
    microData = {
        IQ6: { value: 230, peak: 0.240 },
        'IQ6+': { value: 280, peak: 0.290 },
        IQ7: { value: 240, peak: 0.250 },
        'IQ7+': { value: 290, peak: 0.295 },
        IQ7X: { value: 315, peak: 0.320 },
        IQ7A: { value: 349, peak: 0.366 },
        M175: { value: 168, peak: 0.175 },
        M190: { value: 182, peak: 0.190 },
        M210: { value: 201, peak: 0.210 },
        M215: { value: 215, peak: 0.225 },
        M250: { value: 240, peak: 0.250 },
        D380: { value: 365, peak: 0.380 }
    };

    constructor(params) {
        let IQcount = 0;
        let nonIQCount = 0;
        let m250_210_cnt = 0;
        Object.assign(this, params);
        if (params.inverters) {
            Object.keys(params.inverters).forEach(key => {
                this.pv_modules.push({ type: 'Micro', name: key, count: params.inverters[key] });
                if (key.substr(0, 2) === 'IQ') {
                    IQcount += params.inverters[key];
                } else {
                    if (key.includes('M215') || key.includes('M250')) {
                        m250_210_cnt += params.inverters[key];
                    } else {
                        nonIQCount += params.inverters[key];
                    }
                }
            });
            let pv = this.calculatePanelSize(params.inverters);
            this.pv_size = pv.pvSize;
            this.pv_on_older_micros = pv.pvOlder;
        } else if (params.solar && params.solar.pv_modules) {
            params.solar.pv_modules.map((micro) => {
                if (micro.name.substr(0, 2) === 'IQ') {
                    IQcount += micro.count;
                } else {
                    if (micro.name.includes('M215') || micro.name.includes('M250')) {
                        m250_210_cnt += micro.count;
                    } else {
                        nonIQCount += micro.count;
                    }
                }
            });
            this.pv_modules = params.solar.pv_modules;
            this.pv_size = params.solar.pv_size;
        }
        this.IQCount = IQcount;
        this.non_compatible_invs_count = nonIQCount;
        this.m250_210_count = m250_210_cnt;
        this.total_panels = IQcount + nonIQCount + m250_210_cnt;

        this.encharge_count = this.encharges.length;
        if (params.envoys) {
            params.envoys.forEach(envoy => {
                if (envoy.type.includes('Envoy-S-Metered')) {
                    this.envoySCount++;
                }
            });
        }
        this.iqs = Object.keys(this.inverters);
        // this.IQCount = 10;
        // this.non_compatible_invs_count =10;
        // this.total_panels = IQcount + nonIQCount;

    }

    calculatePanelSize(inverters: {}) {
        let pvSize = 0;
        let pvOnOlderMicros = 0;
        Object.keys(inverters).forEach(key => {
            if (key.includes('IQ6+')) {
                pvSize += Math.round((inverters[key] * 280 * 1.17 / 1000) * 10) / 10;
            } else if (key.includes('IQ6')) {
                pvSize += Math.round((inverters[key] * 230 * 1.17 / 1000) * 10) / 10;
            } else if (key.includes('IQ7A')) {
                pvSize += Math.round((inverters[key] * 349 * 1.17 / 1000) * 10) / 10;
            } else if (key.includes('IQ7X')) {
                pvSize += Math.round((inverters[key] * 315 * 1.17 / 1000) * 10) / 10;
            } else if (key.includes('IQ7+')) {
                pvSize += Math.round((inverters[key] * 290 * 1.17 / 1000) * 10) / 10;
            } else if (key.includes('M175')) {
                pvSize += Math.round((inverters[key] * 168 * 1.17 / 1000) * 10) / 10;
            } else if (key.includes('M190')) {
                pvSize += Math.round((inverters[key] * 182 * 1.17 / 1000) * 10) / 10;
                pvOnOlderMicros += Math.round((inverters[key] * 182 * 1.17 / 1000) * 10) / 10;
            } else if (key.includes('M210')) {
                pvSize += Math.round((inverters[key] * 201 * 1.17 / 1000) * 10) / 10;
                pvOnOlderMicros += Math.round((inverters[key] * 201 * 1.17 / 1000) * 10) / 10;
            } else if (key.includes('M215')) {
                pvSize += Math.round((inverters[key] * 215 * 1.17 / 1000) * 10) / 10;
            } else if (key.includes('M250')) {
                pvSize += Math.round((inverters[key] * 240 * 1.17 / 1000) * 10) / 10;
            } else if (key.includes('D380')) {
                pvSize += Math.round((inverters[key] * 365 * 1.17 / 1000) * 10) / 10;
                pvOnOlderMicros += Math.round((inverters[key] * 365 * 1.17 / 1000) * 10) / 10;
            } else {// IQ7
                pvSize += Math.round((inverters[key] * 240 * 1.17 / 1000) * 10) / 10;
            }
        });
        let validPV = Math.ceil(Number((pvSize / 0.35).toFixed(4))) * 0.35;
        pvSize = Math.round(validPV * 100) / 100;
        validPV = Math.ceil(Number((pvOnOlderMicros / 0.35).toFixed(4))) * 0.35;
        pvOnOlderMicros = Math.round(validPV * 100) / 100;
        return { pvSize: pvSize, pvOlder: pvOnOlderMicros };
    }
}


export class SiteInfo {
    site_id: number;
    site_name: string;
    zip_code: number;
    constructor(siteinfo?: any) {
        Object.assign(this, siteinfo);
    }
}
