export enum StorageManufacturerId {
    electriqPower = '1e3bdb63-6641-4585-a29f-d3b9b85893b4',
    enphase = '1964',
    generac = '66a950cb-f1c9-4f5e-ac76-78ddc92c4d00',
    kohler = '7d94c7f2-59cf-44f3-9fe3-7c3a3063e24a',
    lgChem = '2283',
    simpliPhi = '418eb474-2c4a-44ae-8264-eb4eded05a35',
    sonnenBatterie = '2284',
    sunVault = 'cade237f-8cba-45e6-abdb-55059ffda400',
    tesla = '2282'
}