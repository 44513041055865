export class BOMList {
    lead_details: {
      project_type: string;
      requirement: string;
      save_bomcalculation: string;
      first_name: string;
      last_name: string;
      email: string;
      mobile: string;
      address: string;
      customer_type: string;
      site_id: string;
    };
    pricing_template_id: string;
    company_id: string;
    project_summary: {
      system_size_dc: number;
      system_size_ac: number;
      service_panel_voltage: string;
      inverters_per_phase: number;
      inverters_count: number;
      inverter_type: string;
      dc_ac_ratio: number;
      price_per_wdc: number;
      branch_circuits_count: number;
      branch_circuits_per_phase: string;
    };
    interconnects: [
      {
        name: string;
        panel_type: string;
        panel_wdc: number;
        panel_count: number;
        portrait_panel_count: number;
        landscape_panel_count: number;
        panel_orientation: string;
        panel_width: number;
        panel_height: number;
        service_panel_amperage: string;
        service_panel_voltage: string;
        inverter_type: string;
        is_center_tapping: boolean;
        percentage_of_unused_cable_drops: number;
        compliant_materials_required: boolean;
        generator_supported: boolean;
        is_high_electric_noise: string;
        is_npr_required: boolean;
        is_adv_grid_function_required: boolean;
        maker_id: number | null;
        module_id: number | null;
        module_name: string | null;
        storage_size: number;
        ct_count: number | null;
        cell_modem_required: boolean;
        sunlight_backup_required: boolean;
        load_controllers_required: boolean;
        e3_count: number | null;
        e10_count: number | null;
        existing_micros: any;
        summary_params: {
          system_size_dc: number;
          system_size_ac: number;
          service_panel_voltage: string;
          inverters_per_phase: number;
          inverters_count: number;
          inverter_type: string;
          dc_ac_ratio: number;
          price_per_wdc: number;
          branch_circuits_count: number;
          branch_circuits_per_phase: number;
        };
        bom_items: [
          {
            id: string;
            sequence_number: number;
            name: string;
            category: string;
            sub_category: string;
            last_modified_at: string;
            description: string;
            store_price: number;
            sku: string;
            quantity_per_sku: number;
            estore_url: string;
            website_category: string;
            quantity: number;
            price: number | null;
            distributor_price: number;
            net_price: number;
            discount_amount: number | null;
            is_optional: any;
            solargraf_attributes: string | null;
          } 
        ];
        solar_size: number | null;
        panel_maker: string | null;
        purchase_type: string;
        existing_envoy_count: number | null;
        existing_cell_modem_count: number | null;
        site_id: string | null;
      }
    ];
    project_bomitems: [
      {
        id: string;
        sequence_number: number;
        name: string;
        category: string;
        sub_category: string;
        last_modified_at: string;
        description: string;
        store_price: number;
        sku: string;
        quantity_per_sku: number;
        estore_url: string;
        website_category: string;
        quantity: number;
        price: number | null;
        distributor_price: number;
        net_price: number;
        discount_amount: number | null;
        is_optional: any;
        solargraf_attributes: string | null;
      }
    ]
}