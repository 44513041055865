import { Injectable } from '@angular/core';
import { ApiService } from '../_helpers/api.service';
import { Observable } from 'rxjs';
import { REST_BASE_URL, REST_URLS } from '@se/core/constants';
import { Lead } from '@se/core/models';
import moment from 'moment';
import { map } from 'rxjs/operators';
import { LeadsService } from './leads.service';

@Injectable({
  providedIn: 'root'
})
export class InstallersService {
  selectedLeadJourneyId: string[] = [];

  constructor(private _apiService: ApiService, private _lead: LeadsService) { }
  getMatchingInstallers(email: string, zip: number, customerType: string, needSolar = false, needStorage = false, needAcm = false, needMicro = false, rematch = false, siteId = null): Observable<Lead> {
    return this._apiService.get<Lead>(REST_BASE_URL.B2C, this._apiService.composeUrl([REST_URLS.GET.INSTALLERS]), this._apiService.composeUrlParams(['email', 'zip', 'customer_type', 'need_solar', 'need_micro', 'need_storage', 'need_acm', 'rematch', 'site_id'], [email, zip, customerType, needSolar, needMicro, needStorage, needAcm, rematch, siteId])).pipe(map(res => {
      this._lead.isV3 = res.se_version === 'v3';
      return res;
    }));
  }

  updateState(installerId, journeyType, siteId = null): Observable<any> {
    const date = moment();
    return this._apiService.put(REST_BASE_URL.B2C, this._apiService.composeUrl([REST_URLS.PUT.UPDATE_INSTALLER_STATE]), {}, this._apiService.composeUrlParams(['installer_id', 'journey_type', 'site_id', 'completion_date'], [installerId, journeyType, siteId, date.format('YYYY-MM-DDTHH:mm:ssZZ')]));
  }

  updateInstallerLeadState(state, leadId, InstallerId, journeyType, rejectionReason = null): Observable<any> {
    const date = moment();
    return this._apiService.put(REST_BASE_URL.B2C, this._apiService.composeUrl([REST_URLS.PUT.UPDATE_INSTALLER_LEAD_STATE]), {}, this._apiService.composeUrlParams(['state', 'lead_id', 'installer_id', 'journey_type', rejectionReason ? 'rejection_reason' : null, 'completion_date'], [state, leadId, InstallerId, journeyType, rejectionReason, date.format('YYYY-MM-DDTHH:mm:ssZZ')]))
  }

  getBOM(data): Observable<any> {
    return this._apiService.post(REST_BASE_URL.B2C, this._apiService.composeUrl([REST_URLS.GET.BOM_INFO]), data);
  }

  setCartItems(data): Observable<any> {
    return this._apiService.post(REST_BASE_URL.B2C, this._apiService.composeUrl([REST_URLS.GET.CART]), data);
  }

  getContractDocument(file_name): Observable<any> {
    return this._apiService.get(REST_BASE_URL.B2C, this._apiService.composeUrl([REST_URLS.GET.CONTRACT_DOCUMENT]), this._apiService.composeUrlParams(['file_name', 'folder_name', 'http_method'], [file_name, 'documents', 'GET']));
  }

  get_prices_for_new(zip: string, customer_type: string, need_solar: boolean, need_micro: boolean, need_storage: boolean, need_acm: boolean): Observable<any> {
    return this._apiService.get(REST_BASE_URL.B2C, this._apiService.composeUrl([REST_URLS.GET.GET_PRICES]), this._apiService.composeUrlParams(['zip', 'customer_type', 'need_solar', 'need_micro', 'need_storage', 'need_acm'], [zip, customer_type, need_solar, need_micro, need_storage, need_acm]));
  }

  get_prices_for_existing(email: string, site_id: number, referrer_installer_id: number, zip: string, customer_type: string, need_solar: boolean, need_micro: boolean, need_storage: boolean, need_acm: boolean): Observable<any> {
    return this._apiService.get(REST_BASE_URL.B2C, this._apiService.composeUrl([REST_URLS.GET.GET_PRICES]), this._apiService.composeUrlParams(['email', 'site_id', 'referrer_installer_id', 'zip', 'customer_type', 'need_solar', 'need_micro', 'need_storage', 'need_acm'], [email, site_id, referrer_installer_id, zip, customer_type, need_solar, need_micro, need_storage, need_acm]));
  }

  getSGQuoteDetails(publicId: string) {
    return this._apiService.get(REST_BASE_URL.SG, this._apiService.composeUrl([REST_URLS.GET.SG_QUOTE, publicId]));
  }
}
