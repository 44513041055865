export const APP_ROUTE = {
    COMMON: '/v2',
    LOGIN: '/v2/login',
    REGISTER: '/register',
    THANKYOU: {
        V2: '/v2/thank-you',
        V3: '/v3/thank-you',
        DE: '/de-de/thank-you'
    },
    installerCommonBase: 'installer/',
    installerLoginBase: 'login/installer/',
    V3: '/v3',
    LEAD: {
        common: '/lead',
        details: '/estimator/lead/details',
        new: '/lead/new',
        existing: '/lead/existing'
    },
    INSTALLER: {
        authenticate: {
            details: '/estimator/installers/details',
            match: '/estimator/installers/match',
            compare: '/estimator/installers/compare',
            requestQuote: '/estimator/installers/request-quote'
        },
        guest: {
            match: '/installers/match'
        }
    },
    DE: {
        DE: '/de-de'
    },
    EN: {
        AU: '/en-au',
        IN: '/en-in',
        US: '/'
    },
    ES: {
        ES: '/es-es'
    },
    FR: {
        FR: '/fr-fr',
        BE: '/fr-be'
    },
    NL: {
        BE: '/nl-be',
        NL: '/nl-nl'
    },
    IT: {
        IT: '/it-it'
    },
    MX: '/es-mx',
    PR: '/es-pr',
    MX_LOGIN: '/es-mx/login',
    PR_LOGIN: '/es-pr/login'

};
