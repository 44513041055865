import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material';
import { AlertType } from '@se/core/enums/common.enum';

@Injectable()
export class ToasterService {
  constructor(private matSnackBar: MatSnackBar) { }
  // autoHide = 2500;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  alert(message: string, type: AlertType = AlertType.success, action?: string) {
    // action = action || this.actionButtonLabel;
    // this.getMatConfig(type)
    const snackBarConfig = {
      horizontalPosition: this.horizontalPosition, verticalPosition: this.verticalPosition, duration: 5000, panelClass: ''
    };
    if (type === AlertType.error) {
      snackBarConfig.panelClass = 'mat-snackbar-error';
    } else if (type === AlertType.info) {
      snackBarConfig.panelClass = 'mat-snackbar-info';
    }
    this.matSnackBar.open(message, action, snackBarConfig);
    // if (typeof cb === 'function') {
    //   snackbar.afterDismissed().subscribe(() => { cb(); });
    // }
  }
  // getMatConfig(type: AlertType) {
  //   const config = new MatSnackBarConfig();
  //   config.horizontalPosition = this.horizontalPosition;
  //   config.verticalPosition = this.verticalPosition;
  //   config.duration = this.autoHide;
  //   if (type === AlertType.error) {
  //     config.panelClass = 'mat-snackbar-error';
  //   } else if (type === AlertType.info) {
  //     config.panelClass = 'mat-snackbar-info';
  //   }
  //   return config;
  // }
}
