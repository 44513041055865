import { LeadJourney } from './lead.model';

export class Installer {
    // new_field: string;
    id: string;
    nps: number;
    yelp_account: object;
    google_place: object;
    labor_warranty: number;
    service_type: object | string[];
    company_id: number;
    tier: tier;
    year_founded: number;
    created_at: string;
    installer_status: string;
    is_current_installer: boolean;
    is_turnkey_installer: boolean;
    is_install_only_installer: boolean;
    name: string;
    profile: Profile;
    lead_journeys: LeadJourney[];
    lead_journey: LeadJourney;
    installing_since: string;
    turnkey_solar_new: OptionService;
    turnkey_storage_new: OptionService;
    turnkey_micro_upgrade: OptionService;
    install_only_solar_new: OptionService;
    install_only_storage_new: OptionService;
    install_only_micro_upgrade: OptionService;
    sites_count: number;
    storages_count: number;
    price_breakup: BreakUp;
    platform_for_existing_customers = true;
    constructor(installer?: any) {
        Object.assign(this, installer);
        // this.new_field = 'killerbee';
        // TODO have to convert service_type to string
        this.tier = new tier(installer["tier"]);
        if (installer["profile"]) {
            this.profile = installer["profile"];
        }
        if (installer["lead_journey"]) {
            this.lead_journey = installer["lead_journey"];
        }
    }
}

export class tier {
    rank: number;
    name: string;
    constructor(tier?: any) {
        Object.assign(this, tier);
    }
}

export class BreakUp {
    total_price: Range;
    system_price: number;
    install_only_price: Range;
    incentives_itc: Range;
    incentives_sgip: number;
    estimated_price: Range;
    constructor(breakUp?: any) {
        Object.assign(this, breakUp);
    }
}

export class Range {
    min: number;
    max: number;
    constructor(range?: any) {
        Object.assign(this, range);
    }
}

export class Profile {
    installer_id: number;
    name: string;
    logo_file: string;
    address_type: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    latitude: number;
    longitude: number;
    timezone: string;
    altitude: string;
    override_geocode: string;
    city_geocode_id: number;
    weather_station: string;
    constructor(tier?: any) {
        Object.assign(this, tier);
    }
}

export class OptionService {
    min: number;
    max: number;
    selected: boolean;
    constructor(optionService?: any) {
        Object.assign(this, optionService);
    }
}
