export class UserSession {
    status = '';
    zipCode = '';
    country = '';
    accountId = '';
    prefLseName = '';
    prefLseId = null;
    prefTariffCode = '';
    prefTariffId = null;
    selectedPlan: Plan = null;
    plans: Plan[] = [];
    filteredPlans: Plan[] = [];
    loading = false;
    loadingUsage = false;
    savingsCalculated = false;
    apiCallsInProgress = 0;
    postTariffList = [];
    prefPostTariff = 0; // index
}
// TODO: need to remove selectedPlan

export class Plan {
    lseId: number;
    lseName: string;
    tariffList: Tariff[];
    filteredTariffList: Tariff[];
}

export class Tariff {
    tariffId: number;
    tariffCode: string;
}

export class Usage {
    status = '';
    accountId = '';
    monthlyUsage = null;
}

export class Config {
    steps: number;
    appliances_enabled: boolean;
    configs: string[];
    adt_enabled: boolean;
    sat_enabled: boolean;
    modes: {
        length: number,
        list: Array<any>,
        supported: boolean[]
    };
    currency: string;
    finance_options: string[];
    priority_backup_hours: number;
    adt_storage_dimensions: Object;

}
