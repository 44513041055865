import { Profile } from '../models/installer.model';

export const EXTERNAL_URL = {
    enlighten: {
        forgot_password: "/forgot_password"
    },
    //TODO: Move this to config file
    google_map: (profile: Profile) => {
        if (profile.latitude && profile.longitude) {
            return `https://www.google.com/maps/search/${profile.latitude},${profile.longitude}`;
        }
        const address = [];
        Object.entries(profile).map((item) => {
            if (['address1', 'address2', 'city', 'state', 'zip', 'country'].includes(item[0]) && item[1]) {
                address.push(item[1])
            }
        })
        return `https://www.google.com/maps/search/${encodeURI(address.join(', '))}`;
    }
}