import { Injectable,SecurityContext } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { AuthenticationService } from '../services';
import { APP_ROUTE } from '../constants';
import { throwError } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class RedirectionGuard implements CanActivate {
  // https://ipapi.co/api/?javascript--node#complete-location5
  constructor(private http: HttpClient, private _auth: AuthenticationService, private _router: Router, private sanitizer: DomSanitizer) { }
  url = [
    'enlighten-qa4.enphaseenergy.com', 'qa2.enphaseenergy.com', 'enlighten-qa2.enphaseenergy.com',
    'enlighten.enphaseenergy.com', 'estimator.enphaseenergy.com', 'estimator-qa2.enphaseenergy.com', 'estimator.enphase.com',
    'estimator-qa4.enphase.com', 'estimator-qa2.enphase.com', 'localhost:4200'
  ];
  canActivate(next, state) {
    const { queryParams } = next;
    const url = state.url.split('?')[0];
    const lng = url.split('/')[1];
    const host = this.sanitizer.sanitize(SecurityContext.HTML,window.location.host);
    const hostName = this.sanitizer.sanitize(SecurityContext.HTML,window.location.hostname);
    const origin = this.sanitizer.sanitize(SecurityContext.HTML,window.location.origin);
    const search = this.sanitizer.sanitize(SecurityContext.HTML,window.location.search);
    if (this._auth.isUserLoggedIn && url === '/installers') {
      this._router.navigate(['/estimator/installers']);
      return false;
    } else if(url.includes('/de-de')) {
      window.location.href = 'https://enphase.com/de-de';
      return false;
    } else if(url.includes('/fr-fr')) {
      window.location.href = 'https://enphase.com/fr-fr/installer-locator';
      return false;
    } else if(url.includes('/fr-be')) {
      window.location.href = 'https://enphase.com/fr-be/installer-locator';
      return false;
    } else if(url.includes('/nl-be')) {
      window.location.href = 'https://enphase.com/nl-be/installer-locator';
      return false;
    } else if (url.includes('installer/')) {
      return true;
    } else if (url.includes('/lead')) {
      window.location.href = 'https://start.enphase.com/form-enph/' + search;
      return false;
    } else if (url.includes('v2')) {
      return this.http.get('https://api-prod.secureprivacy.ai/api/adminsettings/displaypage/ipinfo').pipe(map((res: any) => {
        const country = res.countryCode;
        const region = res.region;
        if(country == 'US' && region == 'Connecticut')
        {
          window.location.href = origin + '/v3' + search;
          return false;
        } else {
          return true;
        }
      }), catchError(() => {
        window.location.href = origin + '/v2' + search;
        return throwError(false);
      }));
    } else if (['/thank-you', '/login', '/v2', '/v3', '/v4', '/v2/login', '/v2/thank-you', '/v3/thank-you', '/v4/thank-you', '/pse-de', '/pse-de/thankyou'].includes(url)) {
      return true;
    } else if (['en-au', 'de-de', 'en-in', 'es-es', 'fr-fr', 'fr-be', 'nl-be', 'nl-nl', 'it-it', 'installers', 'es-mx', 'es-pr', 'es-mx/login', 'es-pr/login'].includes(lng)
      || '/en-au/expansion' === url || url.includes('upgrade-thankyou')) {// TODO: installers need to be removed
      return true;
    } else if (queryParams.referral_code || (lng.includes('advanced-appliances') && queryParams.token)) {
      return true;
    } else if (lng === 'lead' && !queryParams.referral_code) {
      this._router.navigate(['/']);
      return false;
    } else if (queryParams.src === 'ho') {
      this._router.navigate([APP_ROUTE.COMMON], { queryParams });
      return false;
    } else if (queryParams.user === 'ISR' || queryParams.referral_code || (lng.includes('advanced-appliances') && queryParams.token)) {
      return true;
    } else if (this.url.includes(host)) {
      return this.http.get('https://api-prod.secureprivacy.ai/api/adminsettings/displaypage/ipinfo').pipe(map((res: any) => {
        const country = res.countryCode;
        const region = res.region;
        if (country === 'US') {
          if(region == 'Connecticut')
          {
            window.location.href = origin + '/v3' + search;
            return false;
          } else {
            window.location.href = origin + '/v2' + search;
            return false;
          }
        } else if (country === 'AU') {
          window.location.href = origin + '/en-au' + search;
          return false;
        } else if (country === 'DE') {
          window.location.href = origin + '/de-de' + search;
          return false;
        } else if (country === 'IN') {
          window.location.href = origin + '/en-in' + search;
          return false;
        } else if (country === 'ES') {
          window.location.href = origin + '/es-es' + search;
          return false;
        } else if (country === 'FR') {
          window.location.href = origin + '/fr-fr' + search;
          return false;
        } else if (country === 'NL') {
          window.location.href = origin + '/nl-nl' + search;
          return false;
        } else if (country === 'BE') {
          if ((navigator.language || navigator['userLanguage']) === 'fr') {
            window.location.href = origin + '/fr-be' + search;
          } else {
            window.location.href = origin + '/nl-be' + search;
          }
          return false;
        } else if (country === 'IT') {
          window.location.href = origin + '/it-it' + search;
          return false;
        } else if (country === 'MX') {
          window.location.href = origin + '/es-mx' + search;
        } else if (country === 'PR') {
          window.location.href = origin + '/es-pr' + search;
        } else {
          window.location.href = (hostName === 'localhost' ? origin : origin + '/v2');
          return false;
        }

      }), catchError(() => {
        window.location.href = origin + '/v2' + search;
        return throwError(false);
      }));
    } else {
      return true;
    }
  }
}