export class User {
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
    address: Address;
    lead_id: string;
    zip: string;
    constructor(userdata?: any) {
        Object.assign(this, userdata);
        if (userdata && userdata['address']) {
            this.address = new Address(userdata['address']);
        }
    }
}

export class Login {
    username: string;
    password: string;
    constructor(login?: any) {
        Object.assign(this, login);
    }
}

export class LoginResponse {
    token: string;
    authdata: string;
    constructor(response?: any) {
        Object.assign(this, response);
    }
}

export class Address {
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    latitude: number;
    longitude: number;
    constructor(address?: any) {
        Object.assign(this, address);
    }
}

export interface AdditionalAppliance {
    name: string;
    power: number;
    dailyUsage: number;
    powerType: string;
    surgeType: string;
    surgePower: number;
    softStarter: boolean;
}

export interface ApplianceGroup {
    category: string;
    appliances: string[];
}

export class UTMParams {
    utm_source: string;
	utm_medium: string;
	utm_campaign: string;
	utm_term: string;
	utm_content: string;
}
