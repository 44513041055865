import { Component, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'ngx-feedback-main-btn',
    templateUrl: './feedback-button.component.html',
    styleUrls: ['./feedback-button.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})
export class FeedbackButtonComponent {
    constructor(private router: Router) { }

    private getActivatedRoute(): ActivatedRoute {
        let route = this.router.routerState.root;
        while (route.firstChild) {
            route = route.firstChild;
        }
        return route;
    }

    openFeedbackDialog() {
        const locale = this.getActivatedRoute()
            && this.getActivatedRoute().snapshot
            && this.getActivatedRoute().snapshot.data
            && this.getActivatedRoute().snapshot.data.locale;
        const formId = locale === 'en-au' ? '15517' : '14947';
        window.fby.push(['showForm', formId]);
    }
}
