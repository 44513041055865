import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SelectivePreloadingStrategyService } from './core/_helpers/selective-preloading-strategy.service';

const routes: Routes = [
  { path: '', loadChildren: () => import('./_layout/layout.module').then(m => m.LayoutModule) }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: SelectivePreloadingStrategyService,
      anchorScrolling: 'enabled',
      // enableTracing: true
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
