import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WebStorageService {

  constructor() { }

  set(key: string, value: any) {
    localStorage.setItem(key, value);
  }
  get(key: string) {
    try {
      const getItem = localStorage.getItem(key);
      if (getItem) {
        return getItem;
      }
    } catch {
      return null;// *NOTE: Fix for incognito mode
    }
    return null;
  }
  destroy(key: string) {
    if (this.get(key)) {
      localStorage.removeItem(key);
    }
  }
}
