import { Component, OnInit, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { WebStorageService, WEB_STORAGE_KEYS } from './core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'System Estimator';
  showFeedbackBtn = false;
  constructor(
    public translate: TranslateService, private router: Router, private titleService: Title,
    private activatedRoute: ActivatedRoute, private _webStorage: WebStorageService, private metaTagService: Meta
  ) {
    this.setLanguage();
    // this.addRouterLoader();
  }
  changeLang(language: string) {
    this._webStorage.set(WEB_STORAGE_KEYS.language, language);
    this.translate.use(language);
  }
  setLanguage() {
    /* this.translate.addLangs(['en', 'fr']);
    if (this._webStorage.get('locale')) {
      const browserLang = this._webStorage.get('locale');
      this.translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
    } else {
      this._webStorage.set('locale', 'en');
      this.translate.setDefaultLang('en');
    } */
    let lng = 'en-us';
    const url = window.location.href;
    if (url.includes('/en-au')) {
      lng = 'en-au';
    } else if (url.includes('/de-de') || url.includes('/pse-de')) {
      lng = 'de-de';
    } else if (url.includes('/en-in')) {
      lng = 'en-in';
    } else if (url.includes('/es-es')) {
      lng = 'es-es';
    } else if (url.includes('/fr-fr')) {
      lng = 'fr-fr';
    } else if (url.includes('/fr-be')) {
      lng = 'fr-be';
    } else if (url.includes('/nl-be')) {
      lng = 'nl-be';
    } else if (url.includes('/nl-nl')) {
      lng = 'nl-nl';
    } else if (url.includes('/it-it')) {
      lng = 'it-it';
    } else if (url.includes('/es-mx')) {
      lng = 'es-mx';
    } else if (url.includes('/es-pr')) {
      lng = 'es-pr';
    }

    this.translate.addLangs(['de-de', 'en-au', 'en-in', 'en-us', 'es-es', 'fr-be', 'fr-fr', 'nl-be', 'nl-nl', 'it-it', 'es-mx', 'es-pr']);
    this.translate.setDefaultLang('en-us');
    this.translate.use(lng);
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    //  this.translate.use('en');
    // fr fr-FR nl(duch) de-DE(germeny) es-ES(spanish) en-AU
    // const browserLang = this.translate.getBrowserCultureLang();
  }
  // addRouterLoader() {
  //   this.router.events.subscribe(e => {
  //     if (e instanceof NavigationStart) {
  //       this.blockUI.start(); // Start blocking
  //     }
  //     if (e instanceof NavigationEnd) {
  //       this.blockUI.stop();
  //     }
  //   });
  // }
  ngOnInit(): void {
    this.setTitle();
  }

  setTitle() {
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((event) => {
      let child = this.activatedRoute.firstChild;
      while (child.firstChild) {
        child = child.firstChild;
      }
      this.showFeedbackBtn = !child.snapshot.data.hideFeedbackBtn;
      let title = this.titleService.getTitle();
      if (child.snapshot.data.title) {
        title = `${this.translate.instant('pageTitle.' + child.snapshot.data.title)}`;
      }
      this.titleService.setTitle(title);
      
      if (child.snapshot.data.description) {//currently description tag used only for sdt
        let description = `${this.translate.instant(child.snapshot.data.description)}`;
        this.metaTagService.updateTag({ name: 'description', content: description });
      } else {
        this.metaTagService.removeTag('name=description');
      }
    });
  }
}
