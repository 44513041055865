import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../_helpers/api.service';
import { REST_BASE_URL, REST_URLS } from '@se/core/constants';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {

  constructor(private _apiService: ApiService) { }
  getDownloadLink(fileName: string): Observable<string> {
    return this._apiService.get(REST_BASE_URL.B2C, this._apiService.composeUrl([REST_URLS.GET.QUOTE_DOWNLOAD_LINK]), this._apiService.composeUrlParams(['file_name', 'http_method'], [fileName, 'GET']))
  }
  verifyPhone(number) {
    return this._apiService.get(REST_BASE_URL.APILAYER, this._apiService.composeUrl([REST_URLS.GET.VERIFY_PHONE]), this._apiService.composeUrlParams(['access_key', 'format', 'country_code', 'number'], ['4bf52d058f311e671bbd3e457b1f54a4', '1', 'US', number]))
  }
}