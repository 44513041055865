import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { Lead, NewSystem, SiteInfo, backupType, SolarRequirement, StorageRequirement } from '@se/core/models';
import { LeadsService } from './leads.service';
import { REST_BASE_URL } from '../constants/rest_server/domains';
import { REST_URLS } from '../constants/rest_server/resturls';
import { ApiService } from '../_helpers';
import { Product_Type } from '../enums';
import { CONS_PROFILE_LIST, BACKUP_MODES } from '../constants';

const defaultAdminSetting = {
  'country': 'US', 'region': 'default',
  'currency': '$', 'storageLossPercentage': 15,
  'inverterOutput': 295, 'solarCostsPerDCWatt': 3.5,
  'storageCost': 960, 'sandiaModPvmoduleName': 'LG_LG290N1C_G3__2013_',
  'cecinverterInverterName': 'Enphase_Energy_Inc___IQ7PLUS_72_x_US__240V___SI1__240V__CEC_2018_',
  'percentageBackupConsumption': 25, 'acConsumption': 6,
  'acDailyUsage': 3, 'evcConsumption': 15, 'evcDailyUsage': 1,
  'ppumpConsumption': 7, 'ppumpDailyUsage': 3, 'netMeterAvailable': 1, 'touRates': '0.32,0.32,0.32,0.32,0.32,0.32,0.32,0.32,0.32,0.32,0.32,0.32,0.38,0.38,0.38,0.38,0.38,0.38,0.32,0.32,0.32,0.32,0.32,0.32'
};

@Injectable({
  providedIn: 'root'
})
export class EstimationService {
  private apiUrl = environment.REST_URL.B2C_NODE;
  private accountId = '';
  constructor(private http: HttpClient, private _leadAPI: LeadsService, private _api: ApiService) { }
  insertLead(f, c, site, sourceDetails, savings, existingLead: Lead = null) {
    const appliances = [];
    const appliancesOutage = [];
    const appliancesConfig = [];

    const have_solar_installer = f.havesolarinstaller || '';
    const system = new NewSystem();

    system.preferred_time_to_call = f.preferredTime ? f.preferredTime.toString() : null;
    if (!c.referralCode && c.load) {
      Object.keys(c.load).forEach(key => {
        const item = c.load[key];
        if (item.selected) {
          appliances.push(key);

          appliancesConfig.push({
            appliance_id: item.applianceId,
            category: item.category,
            code: key,
            daily_usage_hours: item.activeHours,
            enable_for_outage: item.outage,
            name: item.name,
            operating_power: item.operatingPower,
            operating_power_factor: item.powerType,
            quantity: item.selected ? item.qty : 0,
            soft_starter: item.softStarter,
            surge_power: item.surgePower,
            surge_power_factor: item.surgeType,
          });
  
        }
        if (c.selection[c.selectedUser] !== Product_Type.solar && item.outage) {
          appliancesOutage.push(key);
        }
      });
      system.daily_consumption = Math.round(c.consumption);
      system.home_size = c.estimationType === 'ec_bill' ? null : c.homeSize;
      system.hours_of_backup = c.hours;
      system.roof_area_covered = c.product.panel.totalunit * 30.78;
      system.cloudy_days = c.backup ? c.backup.non_sunny_data : null; // confirm with ankit for german SE
      system.sunny_days = c.backup ? c.backup.sunny_data : null;
      system.solar_requirement = null;
      system.storage_requirement = null;
      
      system.simultaneous_appliances = c.multipleUseAppliance;
      if ([Product_Type.solarstorage, Product_Type.solar].includes(c.selection[c.selectedUser]) ||
        c.selectedUser === 'existing' && site && site.non_compatible_invs_count > 0 && !(c.selection[c.selectedUser] === Product_Type.upgrade)) {
        // new customer and existing (Upgrade [panels + micro (IQ)] / Install ACM && Upgrade micro (IQ))
        system.solar_requirement = {
          panel_count: c.product.panel.totalunit,
          pv_size: c.product.panel.value,
          pv_modules: c.product.panel.pv_modules.length > 0 ? c.product.panel.pv_modules : null,
          micro_type: c.product.panel.micro_type
        };
      }
      if ([Product_Type.solarstorage, Product_Type.storage].includes(c.selection[c.selectedUser])) {
        system.storage_requirement = {
          storage_capacity: c.product.encharge.value,
          enc10_count: c.product.encharge.unit10,
          enc3_count: c.product.encharge.unit3,
          suggested_capacity: c.suggested_storage_capacity,
          backup_preference: c.backup ? c.backup.preference : 'wholeHome', // confirm with ankit once for german SE
          site_encharges: null
        };
      }

      if (c.selectedUser === 'existing' && site && site.non_compatible_invs_count === 0 &&
        !(c.selection[c.selectedUser] === Product_Type.upgrade)) { // existing wth all IQ inverters
        system.solar_requirement = null;
      }
    }
    system.selected_appliances = Object.assign([], appliances);
    system.appliances_selected_for_outage = Object.assign([], appliancesOutage);
    system.appliances_config = Object.assign([], appliancesConfig);
    const lead = new Lead();
    if (c.leadId) {
      lead.lead_id = c.leadId;
    }
    lead.slf_shading_info = c.shadingInformation;
    lead.slf_property_type = c.propertyType;
    lead.save_estimate_only_lead = !c.contactEnphase;
    lead.email = f.email;
    lead.heard_about_enphase = f.sourceOfKnowledge;
    lead.customer_type = c.selectedUser;
    lead.have_solar_installer = have_solar_installer;
    lead.phone = f.phone;
    lead.first_name = f.firstName;
    lead.last_name = f.lastName;
    lead.product_type = c.productOfInterest || null;
    lead.zip = f.zipcode;
    lead.address = f.address;
    lead.est_input_option = c.estimationType;
    lead.ec_bill_amt = c.estimationType === 'ec_bill' ? c.electricBill : (savings? savings.currMonthlyBill: null);
    lead.new_system = system;
    lead.existing_system = null;
    lead.unsure_of_system_info = c.unsure_of_system_info;
    lead.existing_panels_count = c.existing_panels_count;
    lead.existing_pv_size = c.existing_pv_size;
    lead.additional_savings_pa = c.additional_savings_pa;
    lead.min_estimated_investment = c.min_estimated_investment;
    lead.gamification_source = c.gamification_source;
    lead.gamification_src_identifier = c.gamification_src_identifier;
    lead.own_house = sourceDetails === 'US IQ8 Platform Lead' ? f.own : c.residenceOwnership;
    lead.installation_timeline = f.installMoment;
    // lead.polygons = c.polygons;
    lead.existing_pv_installation_year = c.existing_installation_year;
    lead.max_estimated_investment = c.max_estimated_investment;
    lead.min_roi = c.min_roi;
    lead.max_roi = c.max_roi;
    lead.gamification_src_comments = c.comments;
    lead.power_generated_pa = c.power_generated_pa;
    lead.additional_info = c.additional_info;
    lead.locale = f.locale;
    lead.main_panel_bus_rating = c.main_panel_bus_rating;
    lead.main_breaker_rating = c.main_breaker_rating;
    lead.created_by_utype = c.created_by_utype;
    lead.created_by_uname = c.created_by_uname;
    lead.lead_referred_by_installer = c.installerId;
    if (c.lead_source === undefined || c.lead_source === null) {
      lead.lead_source = 'System Estimator';
    } else {
      lead.lead_source = c.lead_source;
    }
    lead.source_details = sourceDetails;
    lead.system_sized_by = c.system_sized_by;
    lead.electric_utility = f.electricUtility;
    lead.full_name = f.fullName;
    lead.full_address = f.fullAddress;
    lead.time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    lead.page_url = window.location.href;
    lead.system_summary_data = c.summaryData ? c.summaryData : '';

    if (f.utmParams) {
      lead.utm_params = f.utmParams;
    }

    if (savings) {
      lead.se_version = 'v3';
      lead.selected_utility_provider = savings.prefLseName;
      lead.selected_utility_rate_structure = savings.prefTariffCode;

      lead.selected_finance_option = savings.finOption;
      lead.selected_consumption_timings = savings.consumptionProfile;
      lead.annual_increase_bill_rate = savings.annualBillIncRate;
      const backupMode = BACKUP_MODES.find(i => i.value === savings.backupMode);
      lead.storage_mode = backupMode ? backupMode.key : null;
      lead.loan_downpayment = savings.downPayment;
      lead.new_electricity_bill = savings.newMonthlyEBill;
      lead.total_cost = savings.upFrontCost;
      lead.fed_tax_inc_rate = savings.federalTaxIncRate;
      lead.sgip_inc_rate = savings.sgipRate;
      lead.itc_amount = savings.federalTaxCredit;
      lead.sgip_amount = savings.sgipInc;
      lead.incentive_amount = savings.sgipInc + savings.federalTaxCredit;
      lead.total_savings = savings.loan.totalSavings;
      lead.loan_interest_rate = savings.loanIntRate;
      lead.loan_term = savings.loanTerm;
      lead.solar_price = savings.solarPrice;
      lead.storage_price = savings.storagePrice;
      lead.size_preference_unit = savings.enchargeMultiFactor;
      lead.loan_amount = savings.intialCost - savings.downPayment;
      lead.loan_emi = savings.monthlyLoanPayment;
      lead.energy_independence = savings.energyInd;
      lead.tariff_rate = savings.tariff_rate || null;

      if (savings.backupOfSystemMode) {
        lead.consumption_preference = savings.dailyConsumptionUnit.label;
        lead.ei_mode_settings = {
          selected_appliances: savings.selectedAppliances.EI,
          appliances_selected_for_outage: savings.backupOfSystemMode.EI,
          simultaneous_appliances: savings.simultaneousUsageOfSystemMode.EI,
          hours_of_backup: savings.intialValues.hours[1],
          pv_size: savings.compareBackupModes[0].pvRec.value,
          storage_size: savings.compareBackupModes[0].storRec.capacity,
        };
        lead.savings_mode_settings = {
          selected_appliances: savings.selectedAppliances.MEB,
          appliances_selected_for_outage: savings.backupOfSystemMode.MEB,
          simultaneous_appliances: savings.simultaneousUsageOfSystemMode.MEB,
          hours_of_backup: savings.intialValues.hours[2],
          pv_size: savings.compareBackupModes[2].pvRec.value,
          storage_size: savings.compareBackupModes[2].storRec.capacity,
        };
        lead.backup_mode_settings = {
          selected_appliances: savings.selectedAppliances.PB,
          appliances_selected_for_outage: savings.backupOfSystemMode.PB,
          simultaneous_appliances: savings.simultaneousUsageOfSystemMode.PB,
          hours_of_backup: savings.intialValues.hours[0],
          pv_size: savings.compareBackupModes[1].pvRec.value,
          storage_size: savings.compareBackupModes[1].storRec.capacity,
        };
      }
    }

    if (c.selectedUser === 'existing' && site) {
      lead.existing_system = {
        site_id: site.site_id,
        storage: {
          site_encharges: site.encharges
        },
        solar: {
          panel_count: site.total_panels,
          pv_modules: site.pv_modules,
          pv_size: site.pv_size
        },
      };
    }
    lead.program_name = f.program;
    if (c.selection) {
      if (c.selection[c.selectedUser] === Product_Type.upgrade) { // 2nd option for existing user (Upgrade your Solar System)
        lead.new_system = null;
      }

      lead.solar_req = c.selection[c.selectedUser].includes('solar') || c.selection[c.selectedUser] === Product_Type.upgrade || (c.selectedUser === 'existing' && c.upgradeType === 'Full-System Upgrade');
      lead.storage_req = c.selection[c.selectedUser].includes('storage');
      lead.micro_req = c.selectedUser === 'existing' && site && site.non_compatible_invs_count > 0;
      lead.solar_add_req = c.selectedUser === 'existing' && c.selection[c.selectedUser] !== Product_Type.upgrade && (c.product.panel.totalunit > c.existing_panels_count);
    }

    if (lead.solar_req && lead.storage_req && lead.micro_req && lead.solar_add_req) {
      lead.micro_req = false;
      lead.solar_add_req = false;
    }
    let req_val = 0;
    req_val = lead.solar_req ? req_val + 8 : req_val;
    req_val = lead.storage_req ? req_val + 4 : req_val;
    req_val = lead.micro_req ? req_val + 2 : req_val;
    req_val = lead.solar_add_req ? req_val + 1 : req_val;

    switch (req_val) {
      case 14: lead.requirement = 'Add Storage & Upgrade Solar';
        break;
      case 12: lead.requirement = 'Solar & Storage';
        break;
      case 10: lead.requirement = 'Upgrade Solar';
        break;
      case 8: lead.requirement = 'Solar Only';
        break;
      case 6: lead.requirement = 'Add Storage & Upgrade Microinverters';
        break;
      case 4: lead.requirement = 'Storage Only';
        break;
      case 2: lead.requirement = 'Upgrade Microinverters';
        break;
      case 5: lead.requirement = 'Solar (Additional) & Storage';
        break;
      case 7: lead.requirement = 'Add Storage & Solar (additional) & Upgrade Microinverters';
        break;
      default: lead.requirement = '';
        break;
    }
    if (existingLead) {
      lead.stage = existingLead.stage;
      lead.journey = existingLead.journey;
      lead.version = existingLead.version;
      lead.created_time = existingLead.created_time;
      return this._leadAPI.updateExistingLead(lead, existingLead.lead_id)
    }
    return this._leadAPI.insertLead(lead);
  }

  // If advanced Options in SE page is clicked once, variable will be true , pdf will be send via mail if advancedOptionsClicked is true
  createLeadInSFDC(f, u, c, usersession, utmSource, leadId, site, page_url, source_details, summary_html_data, advanceOptionsClicked, advancedOptionsEdited, savings, LCuserSession) {
    if (c.hasOwnProperty('contactEnphase') && !c.contactEnphase) {
      return new Observable((observer) => { observer.next(); });
    }
    let appliances = [];
    let appliancesOutage = [];

    if (c.load && Object.keys(c.load)) {
      Object.keys(c.load).forEach(key => {
        const item = c.load[key];
        if (item.selected) {
          appliances.push(key);
        }
        if (c.selection[c.selectedUser] !== Product_Type.solar && item.outage) {
          appliancesOutage.push(key);
        }
      });
    }


    const program = f.program || '';
    const installer_company_name = f.companyname || '';
    const have_solar_installer = f.havesolarinstaller || '';
    const applianceStr = appliances.toString();
    const enlUrl = u && u.enlighten_user_id ? (`${environment.ENLIGHTEN_URL.userProfile}${u.enlighten_user_id}`) : '';
    const registrationUrl = `${window.location.hostname === 'localhost' ? environment.ENLIGHTEN_URL.main + '/estimator' : window.location.hostname}/installers/match?zipcode=${u.zip}${encodeURIComponent('&email=' + f.email)}`;
    let params = `?first_name=${encodeURIComponent(f.firstName)}&last_name=${encodeURIComponent(f.lastName)}&email=${encodeURIComponent(f.email)}&additional_info=${f.additional_info}&contact_number=${f.phone}`;
    params += `&address1=${encodeURIComponent(u.address1)}&address2=${encodeURIComponent(u.address2)}&city=${u.city}&state=${u.state}&zipcode=${u.zip}&country=${u.country}`;
    params += `&preferred_time=${f.preferredTime}&consent=${f.consent}&product=${Product_Type[c.selection[c.selectedUser]]}&home_size=${c.homeSize}`;
    params += `&pv_capacity=${c.product.panel.value}&storage=${c.product.encharge.value}&appliances=${applianceStr}&hours_of_backup=${c.hours}`;
    params += `&campaign_source=${utmSource.utm_source}&campaign_medium=${utmSource.utm_medium}&campaign_name=${utmSource.utm_campaign}`;
    params += `&campaign_content=${utmSource.utm_content}&search_term=${utmSource.utm_term}&have_solar_installer=${have_solar_installer}`;
    params += `&program=${program}&enlighten_id=${u.enlighten_user_id}&installer_company_name=${installer_company_name}&user_type=${c.selectedUser}`;
    params += `&appliances_outage=${appliancesOutage.toString()}&lead_id=${leadId}&${usersession}&no_journey_lead=${f.no_journey_lead}`;
    params += `&est_input_option=${c.estimationType}&ec_bill_amt=${c.estimationType === 'ec_bill' ? c.electricBill : ''}`;
    params += `&user_profile_link=${enlUrl}&existing_pv_installation_year=${c.existing_installation_year}&heard_about_enphase=${f.sourceOfKnowledge}`;
    params += `&unsure_of_system_info=${c.unsure_of_system_info}&existing_panels_count=${c.existing_panels_count}&existing_pv_size=${c.existing_pv_size}`;
    params += `&additional_savings_pa=${c.additional_savings_pa}&min_estimated_investment=${c.min_estimated_investment}&gamification_source=${c.gamification_source}`;
    params += `&gamification_src_identifier=${c.gamification_source_id}&is_own_house=${c.own_house}`;
    if (savings) {
      const backupMode = BACKUP_MODES.find(i => i.value === savings.backupMode);
      const backupModeKey = backupMode ? BACKUP_MODES[savings.backupMode].key : null;
      params += `&storage_mode=${encodeURIComponent(backupModeKey)}`;
      params += `&total_cost=${savings.upFrontCost}&incentive_amount=${savings.sgipInc + savings.federalTaxCredit}&total_savings=${savings.loan.totalSavings}`;
      params += `&new_electricity_bill=${savings.newMonthlyEBill}&loan_amount=${savings.finOption === 'loan' ? savings.intialCost - savings.downPayment : 0}&loan_emi=${savings.finOption === 'loan' ? savings.monthlyLoanPayment : 0}`;
    }

    params += `&max_estimated_investment=${c.max_estimated_investment}&min_roi=${c.min_roi}&max_roi=${c.max_roi}&gamification_src_comments=${c.gamification_src_comments}`;
    params += `&power_generated_pa=${c.power_generated_pa}`;
    params += `&registration_url=${registrationUrl}`;

    const formURL = `${environment.RESOURCES.SFDC}${params}`;
    let pdf_link = '';

    let pdf_enabled_source_details = ['US SE V3 Platform Lead', 'US Platform Lead', 'Enphase System Estimator', 'Germany System Estimator'];
    if (pdf_enabled_source_details.includes(source_details)) {
      pdf_link = 'lead_images/' + leadId + '_system_summary.pdf';
      summary_html_data = btoa(unescape(encodeURIComponent(summary_html_data)));
    } else {
      summary_html_data = '';
    }

    const lc_backup_hours = (source_details === 'Germany System Estimator') ? null : c.hours;
    let lc_storage_modes = null;
    let paybackPeriod = null;
    let elecBill = c.estimationType === 'ec_bill' ? c.electricBill : '';
    let homeSize = c.homeSize;
    if (savings) {
      const backupMode = BACKUP_MODES.find(i => i.value === savings.backupMode);
      lc_storage_modes = (source_details === 'Germany System Estimator') ? 'Energy Independence' : ((savings.backupMode !== null) ? backupMode.key : null);
      paybackPeriod = savings.finOption === 'cash' ? savings.cash.paybackPeriod : savings.loan.paybackPeriod;
    }

    if (source_details === 'Germany System Estimator') {
      if (c.estimationType !== 'ec_bill') {
        elecBill = (c.consumption * 30 * savings.tariff_rate + 9.5);
      }
      if (c.estimationType !== 'home_size') {
        homeSize = c.consumption * 30 * 5 / 17;
      }
    }

    // preparing data for LC conduit
    const lcBody = {
      first_name: (f.firstName),
      last_name: (f.lastName),
      phone_1: f.phone,
      product: Product_Type[c.selection[c.selectedUser]],
      address_1: (u.address1),
      address_2: (u.address2),
      city: u.city,
      state: u.state,
      country: u.country,
      email: f.email,
      postal_code: u.zip,
      preferred_contact_time_est_enph: f.preferredTime || null,
      big_is_your_house_enph: homeSize,
      pv_capacity_output_enph: c.selectedUser === 'existing' && c.existingSys && c.upgradeType !== 'Full-System Upgrade' ? Number(((c.product.panel.value - c.existingSys.value)).toFixed(1)) : c.product.panel.value,
      storage_output_enph: c.product.encharge.value,
      appliances_enph: applianceStr,
      backup_hours_enph: lc_backup_hours,
      consent_enph: f.consent,
      campaign_source: utmSource.utm_source,
      campaign_medium: utmSource.utm_medium,
      campaign_name: utmSource.utm_campaign,
      campaign_content: utmSource.utm_content,
      search_terms: utmSource.utm_term,
      lead_location_auto_detect_enph: LCuserSession.time_zone,
      have_solar_installer_enph: have_solar_installer,
      system_owner_program_enph: program,
      enlighten_id_enph: u.enlighten_user_id || null,
      installer_company_name_enph: installer_company_name,
      customer_type_enph: c.selectedUser,
      appliances_during_outage_enph: appliancesOutage.toString(),
      time_spent_on_page_enph: LCuserSession.time_spent_on_page,
      os_version_enph: LCuserSession.os_version,
      browser_name_enph: LCuserSession.browser_name,
      browser_version_enph: LCuserSession.browser_version,
      navigator_user_agent_enph: LCuserSession.navigator_user_agent,
      navigator_app_version_enph: LCuserSession.navigator_app_version,
      navigator_platform_enph: LCuserSession.navigator_platform,
      navigator_vendor_enph: LCuserSession.navigator_vendor,
      // form_id_enph: , // what to put ?
      lead_id_enph: leadId,
      no_installer_lead_enph: f.no_journey_lead,
      est_input_option_enph: c.estimationType,
      ec_bill_amt_enph: elecBill,
      user_profile_link_enph: enlUrl,
      referral_code_enph: u.referralCode,
      original_source: source_details,
      lead_source_page_url_enph: page_url,
      summary_filename_enph: pdf_link,
      storage_mode_enph: lc_storage_modes,
      total_cost_enph: savings ? savings.upFrontCost : null,
      incentive_amount_enph: savings ? savings.sgipInc + savings.federalTaxCredit : null,
      total_savings_enph: savings ? savings.loan.totalSavings : null,
      new_electricity_bill_enph: savings ? savings.newMonthlyEBill : null,
      loan_emi_enph: savings ? (savings.finOption === 'loan' ? savings.monthlyLoanPayment : 0) : null,
      loan_amount_enph: savings ? (savings.finOption === 'loan' ? savings.intialCost - savings.downPayment : 0) : null,
      registration_url_enph: registrationUrl,
      tariff_rate_enph: savings && savings.tariff_rate,
      energy_independence_enph: savings && savings.energyInd,
      payback_period_enph: paybackPeriod,
      panel_count_enph: c.product && c.product.panel && (c.selectedUser === 'new' || (c.selectedUser === 'existing' && c.upgradeType === 'Full-System Upgrade')) ? c.product.panel.totalunit : (c.selectedUser === 'existing' ? Number(((c.product.panel.value - c.existingSys.value) / c.energyPerPanel).toFixed(0)) : null),
      finance_preference_enph: savings && savings.finOption,
      existing_pv_capacity_enph: c.selectedUser === 'existing' && c.existingSys && c.upgradeType !== 'Full-System Upgrade' ? c.existingSys.value : null,
      house_number: f.locale === 'nl-nl' ? f.housenumber : null
    };

    return this._api.post(REST_BASE_URL.B2C, `${this._api.composeUrl([REST_URLS.GET.DRUPAL_MICRO_LINK])}?lead_id=${leadId}&referral_code=${u.referralCode}&system_summary_link=${pdf_link}&source_details=${source_details}&page_url=${page_url}`, { formURL, summary_html_data, advancedOptionsEdited, advanceOptionsClicked, lcBody });
  }

  addToCart(skuDetails) {
    return this._api.post(REST_BASE_URL.B2C, `${this._api.composeUrl([REST_URLS.POST.CART])}`, { skuDetails });
  }

  getAppliances(): Observable<any> {
    return this._api.get(REST_BASE_URL.B2C, this._api.composeUrl([REST_URLS.GET.APPLIANCES]));
  }

  platformStatus(email, siteId = null): Observable<HttpResponse<any>> {
    return this._api.get(REST_BASE_URL.B2C, this._api.composeUrl([REST_URLS.GET.PLATFORM_STATUS]), this._api.composeUrlParams(['email', 'site_id'], [email, siteId]));
  }

  getTimeZone(lat: number, lon: number): Observable<HttpResponse<any>> {
    const url = this.apiUrl + '/timezone/findByLocation?lat=' + lat + '&long=' + lon;
    return this.http.get<any>(url, {
      observe: 'response'
    });
  }

  getSiteDetails(enlightenID, zip, enlightenEmail = '') {
    const url = `${environment.REST_URL[REST_BASE_URL.B2C]}/systems/system_meta_data?site_id=${enlightenID}&email=${enlightenEmail}&zipcode=${zip}&include_site_access_check=1`;
    return this.http.get<any>(url);
  }

  getSiteConsumptionDetails(siteid, zip, enlightenEmail = '') {
    const url = `${environment.REST_URL[REST_BASE_URL.B2C]}/systems/profile?site_id=${siteid}&zipcode=${zip}&email=${enlightenEmail}`;
    return this.http.get<any>(url);
  }

  // getCommonHeaders() {
  //   var auth = window.btoa('admin:admin123');
  //   return { headers: { Authorization: 'Basic ' + auth } };
  // }

  // validateAddressOrZip(input: string, type: string) {
  //   if (type === 'address') {
  //     return this.validateAddress(input);
  //   }
  //   if (type === 'zip') {
  //     return this.validateZip(input);
  //   }
  // }

  // validateAddress(address: string) {
  //   return this.http.get(this.apiUrl + '/gapi/geocode/findByAddress?address=' + address, this.getCommonHeaders());
  // }

  // validateZip(zip: string) {
  //   return this.http.get(this.apiUrl + '/gapi/geocode/findByZip?postal_code=' + zip);
  // }

  getEnergyYield(estimatorInput: any): Observable<HttpResponse<any>> {
    var lat = estimatorInput.lat;
    var long = estimatorInput.lng;
    // TODO: we are passing only following constent for api
    var pvmoduleName = defaultAdminSetting.sandiaModPvmoduleName;
    var inverterName = defaultAdminSetting.cecinverterInverterName;
    var timezone = estimatorInput.timezone;
    var url = this.apiUrl + '/estimation/getHourlyEnergyYields?timezone=' + timezone + '&lat=' + lat + '&lon=' + long + '&pvmoduleName=' + pvmoduleName + '&inverterName=' + inverterName;

    return this.http.get<any>(url, {
      observe: 'response'
    });
  }

  getSiteInfo(): Observable<SiteInfo[]> {
    return this._api.get<SiteInfo[]>(REST_BASE_URL.B2C, this._api.composeUrl([REST_URLS.GET.SITE_INFO]));
  }

  getSiteAccessInfo(): Observable<SiteInfo[]> {
    return this._api.get<SiteInfo[]>(REST_BASE_URL.B2C, this._api.composeUrl([REST_URLS.GET.SITE_ACCESS_INFO]));
  }

  sendSummaryHTML(summary_html_data: string, fileName: string, upload: boolean, lead_id: string, return_summary_link: boolean) {
    return this._api.post(REST_BASE_URL.B2C, this._api.composeUrl([REST_URLS.GET.SUMMARY_HTML]), { summary_html_data, fileName, upload, lead_id, return_summary_link });
  }

  getImpersonatingUserInfo(email: string): Observable<HttpResponse<any>> {
    const url = environment.REST_URL.B2C + '/users/impersonating_user_info';
    return this.http.get<any>(url);
  }

  getInstallerLogoInfo(installerName: string) {
    return this._api.get(REST_BASE_URL.B2C,
      this._api.composeUrl([REST_URLS.GET.INSTALLER_LOGO_INFO]), this._api.composeUrlParams(['installer_name'], [installerName]));
  }

  ADTPost(accountId, roof_segment_stats, rank) {
    return this._api.post(REST_BASE_URL.SAT, `${this._api.composeUrl([REST_URLS.POST.ADTPost])}`, { accountId, roof_segment_stats, rank });
  }

  applyForUpgradeProgram(email, site_id, page_url) {
    return this._api.post(REST_BASE_URL.B2C, this._api.composeUrl([REST_URLS.POST.APPLYUPGRADEPROGRAM]), { email, site_id, page_url });
  }

  ADTPostDE(accountId, roof_segment_stats, rank) {
    return this._api.post(REST_BASE_URL.GERMNAYSE, `${this._api.composeUrl([REST_URLS.POST.GERMANY.ADTPost])}`, { accountId, roof_segment_stats, rank });
  }

  getSEConfig(country_code: string = 'us') {
    return this._api.get(REST_BASE_URL.B2C, `${this._api.composeUrl([REST_URLS.GET.SE_CONFIG])}/${country_code.toLowerCase()}`);
  }

}
