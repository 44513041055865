import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient, HttpParams, HttpParameterCodec } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { REST_BASE_URL } from '@se/core/constants/rest_server/domains';
import { ResponseError } from '@se/core/models';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private http: HttpClient
  ) { }

  private formatErrors(error: any) {
    let response = new ResponseError(error);
    return throwError(response);
  }

  getLocale<T>(
    baseurl: REST_BASE_URL,
    path: string,
    params: HttpParams = new HttpParams({ encoder: new CustomEncoder() })
  ): Observable<any> {
    return this.http
      .get<T>(`${this.getBaseUrl(baseurl)}${path}`, { params })
      .pipe(catchError(this.formatErrors));
  }

  get<T>(baseurl: REST_BASE_URL, path: string, params: HttpParams = new HttpParams({ encoder: new CustomEncoder() })): Observable<any> {
    return this.http.get<T>(`${this.getBaseUrl(baseurl)}${path}`, { params })
      .pipe(catchError(this.formatErrors));
  }

  put(baseurl: REST_BASE_URL, path: string, body: Object = {}, params: HttpParams = new HttpParams({ encoder: new CustomEncoder() })): Observable<any> {
    return this.http.put(
      `${this.getBaseUrl(baseurl)}${path}`,
      JSON.stringify(body), { params }
    ).pipe(catchError(this.formatErrors));
  }

  post(baseurl: REST_BASE_URL, path: string, body: Object = {}): Observable<any> {
    return this.http.post(
      `${this.getBaseUrl(baseurl)}${path}`,
      (body)
    ).pipe(catchError(this.formatErrors));
  }

  delete(baseurl: REST_BASE_URL, path): Observable<any> {
    return this.http.delete(
      `${this.getBaseUrl(baseurl)}${path}`
    ).pipe(catchError(this.formatErrors));
  }
  composeUrlParams(keys, values) {
    // https://github.com/angular/angular/issues/18261#issuecomment-338354119
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });
    for (let i = 0; i < keys.length; i++) {
      if (values[i] === '' || values[i] === undefined || values[i] === null) {
        continue;
      }
      httpParams = httpParams.append(keys[i], values[i]);
    }
    return httpParams;
  }

  composeUrl(urlKeys) {
    let urlArray = new Array();
    for (let i = 0; i < urlKeys.length; i++) {
      if (urlKeys[i] === '' || urlKeys[i] === undefined || urlKeys[i] === null) {
        continue;
      }
      urlArray.push(urlKeys[i]);
    }
    return (urlArray.length) ? urlArray.join('/') : '';
  }
  getBaseUrl(domain: REST_BASE_URL) {
    return environment.REST_URL[domain] || environment.REST_URL.B2C;
  }
}

class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
