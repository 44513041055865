
export const environment = {
  production: false,
  mock: false,
  deployTime: '{BUILD_TIME}',
  REST_URL: {
    B2C: 'https://dc-stg.enphaseenergy.com/api/v1', // Keys are referenced in multiple places
    B2C_NODE: 'https://estimator-qa.enphaseenergy.com/api',
    SOLAR_ROOF: 'https://adt-dev.solargraf.com',
    SAT: 'https://sat-develop.solargraf.com',
    SOLARGRAFAPI: 'https://api-staging.solargraf.com',
    SOLARGRAFAPP: 'https://staging.solargraf.com',
    GERMNAYSE: 'https://sat-staging.solargraf.com',
    APILAYER: "https://apilayer.net",
    BOMCALCULATOR: "https://bom-api-stg.enphaseenergy.com/api/v1",
    FINANCEAPI: "https://5saueiymshd7e5szae7ps6s3ii0dfbnb.lambda-url.us-east-1.on.aws/",
    ENPHASEWEBSITE: "https://enphase.com/api/v1"
  },
  RESOURCES: {
    LOGO_PATH: 'https://enphasedevtest-qa2-files.s3.amazonaws.com/logo_imgs/',
    LOGO_SUB_PATH: '/og/',
    SFDC: 'https://staging-new-enphase.pantheonsite.io/en-us/global-system-estimator/data ',
    STORE_IFRAME: 'https://staging-new-enphase.pantheonsite.io/en-us/enphase-us-installer'
  },
  ENLIGHTEN_URL: {
    main: 'https://qa2.enphaseenergy.com',
    privacy: 'https://enphase.com/privacy',
    terms: 'https://enphase.com/terms',
    ensemble: 'https://enphase.com/en-us/ensemble-technology-enphase-installers',
    oldEstimator: 'https://qa2.enphaseenergy.com/estimator/v3',
    userProfile: 'https://qa2.enphaseenergy.com/admin/users/',
    // thankYou: 'https://staging-new-enphase.pantheonsite.io/en-us/get-quote-thank-you',
    thankYou: {
      'en-us': 'https://staging-new-enphase.pantheonsite.io/en-us/get-quote-thank-you',
      'en-in': 'https://staging-new-enphase.pantheonsite.io/en-in/get-quote-thank-you',
      'en-au': 'https://staging-new-enphase.pantheonsite.io/en-au/get-quote-thank-you',
      'fr-fr': 'https://staging-new-enphase.pantheonsite.io/fr-fr/merci-de-votre-interet',
      'de-de': 'https://staging-new-enphase.pantheonsite.io/de-de/danke-fur-ihre-interesse',
      'nl-nl': 'https://staging-new-enphase.pantheonsite.io/nl-nl/bedankt-voor-uw-interesse',
      'nl-be': 'https://staging-new-enphase.pantheonsite.io/nl-nl/bedankt-voor-uw-interesse',
      'fr-be': 'https://staging-new-enphase.pantheonsite.io/fr-fr/merci-de-votre-interet',
      'es-es': 'https://www4.enphase.com/es-es/obtener-cotizaci%C3%B3n-gracias',
      'referral': {
        'en-au': 'https://staging-new-enphase.pantheonsite.io/en-au/thank-you-referral-leads'
      },
      'it-it': "https://www4.enphase.com/it-it/ottenere-preventivo-grazie"
    }
  },
  APP: {
    version: '1.7.0',
    installer_count: 5,
    selected_installers_limit: 3,
    deployTime: '{BUILD_TIME}'
  },
  KEYS: {
    SAT: "Basic WkM2S2NpcDRTME91M2V4dTlSR2xBVlFCZUF4QlRPdnA6SjBXOWNpYzhET053ZXIzOE1vcVpuV2RTMFh6RmNtckE=",
    BOM: "Partner.nmdhPzKYJiIhfsMfnt/P6HoQYM+FWVP3mgEiACL51SRHorMNSVRNqUQms7D0ZKXZJD1yiPh6A8l8sxycg2Fq6w=="
  },
  OFFGRID:true
};
