import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpEvent, HttpHandler, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services';
import { environment } from '@environments/environment';

@Injectable()
export class AuthInterceptors implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = this.addAuthentication(request);
    return next.handle(request);
  }

  addAuthentication(request: HttpRequest<any>): HttpRequest<any>{
    // add authorization header with basic auth credentials if available
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser && currentUser.token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.token}`
        }
      });
    }
    if(request.url.includes(environment.REST_URL.SAT) || request.url.includes(environment.REST_URL.GERMNAYSE)) 
    {
      request = request.clone({
        setHeaders: {
          Authorization: environment.KEYS.SAT
        }
      });
    }
    if(request.url.includes(environment.REST_URL.BOMCALCULATOR)) {
      request = request.clone({
        setHeaders: {
          Authorization: environment.KEYS.BOM
        }
      });
    }
    return request;
  }
}

export let authInterceptors = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptors,
  multi: true
};
