import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// TODO need to remove mat devider
import 'hammerjs';
import { AppRoutingModule } from './app-routing.module';
import { FeedbackButtonComponent } from './core/component/feedback-button/feedback-button.component';
import { AppComponent } from './app.component';
// import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CoreModule } from './core/core.module';
import { TranslateModule, TranslateLoader, MissingTranslationHandlerParams, MissingTranslationHandler, TranslateCompiler } from '@ngx-translate/core';
import { environment } from '@environments/environment';
import { GestureConfig } from '@angular/material/core';
import { from, Observable } from 'rxjs';


// required for AOT compilation
// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http, './assets/i18n/', '.json');
// }

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return from(import(`../assets/i18n/${lang}.json`));
  }
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    if (!environment.production) {
      return `*<<== ${params.key} ==>>*`;
    }
    return '***********';
  }
}



export class CustomCompiler extends TranslateCompiler {
  /*
  * Needed by ngx-translate
  */
  public compile(value: string): string {
    return value;
  }

  /*
  * Triggered once from TranslateCompiler
  * Initiates recurive this.parseReferencePointers()
  * Returns modified translations object for ngx-translate to process
  */
  public compileTranslations(translations: any) {
    this.parseReferencePointers(translations, translations);
    return translations;
  }

  /*
   * Triggered once from this.compileTranslations()
   * Recursively loops through an object,
   * replacing any property value that has a string starting with "@APP_CORE." with the APP_CORE global string definition.
   * i.e. @APP_CORE.LOCATION.OVERVIEW becomes Location Overview
   */
  private parseReferencePointers(currentTranslations, masterLanguageFile) {
    Object.keys(currentTranslations).forEach((key) => {
      if (currentTranslations[key] !== null && typeof currentTranslations[key] === 'object') {
        this.parseReferencePointers(currentTranslations[key], masterLanguageFile);
        return;
      }
      if (typeof currentTranslations[key] === 'string') {
        if (currentTranslations[key].includes('@:')) {
          const replacementProperty = this.getDescendantPropertyValue(masterLanguageFile, currentTranslations[key].substring(2));
          currentTranslations[key] = replacementProperty;
        }
      }
    });
  }

  /*
   * Takes a string representation of an objects dot notation
   * i.e. "APP_CORE.LABEL.LOCATION"
   * and returns the property value of the input objects property
   */
  private getDescendantPropertyValue(obj, desc) {
    const arr = desc.split('.');
    while (arr.length && (obj = obj[arr.shift()]));
    return obj;
  }
}

@NgModule({
  declarations: [AppComponent, FeedbackButtonComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule.forRoot(),
    // ngx-translate and the loader module
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useClass: WebpackTranslateLoader, deps: [HttpClient] },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
      compiler: { provide: TranslateCompiler, useClass: CustomCompiler },
      useDefaultLang: true
    }),
  ],
  providers: [{ provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig }],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    if (environment.APP.version) {
      (<any>window).appVersion = environment.APP.version;
    }
  }
}
