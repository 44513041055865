export class Message {
    lead_id?: String;
    installer_id?: String;
    journey_type?: String;
    installer_lead_journey_id?: String;
    subject: String;
    message_date?: Date;
    body_content: Date;
    send_by_user_type: String;
	// long created_time;
	// long updated_time;	
	
	// String subject; // predefined subject
	// Date message_date;
	// String body_content;
	// List<EPDocument> files;
	// String send_by_user_type; // homeowner, installer
	// ArrayList<String> read_by; // email IDs
	// ArrayList<String> mail_sent; 
}